//this is going to store Firebase realtime database API code
import { db } from "./firebase";

//##########3 user API

//create an user and store it at users/id path (it's an asynchronous func)
export const addToUsersTable = (id, email, stripeCustomer) => 
  db.ref(`users/${id}`).set({
    email,
    uid: id,
    stripeId: stripeCustomer.id
  });

  export const addToSitesTable = (id, email, stripeCustomer, businessName, subdomain) => 
  db.ref(`sites/${id}`).set({
    uid: id,
    id: id,
    subdomain: subdomain || `${businessName}-${Math.floor(1000 + Math.random() * 9000)}`,
    domain: "tourboogie.com",
    stripeId: stripeCustomer.id,
    "global" : {
      "footer" : {
        "logo" : {
          "mime" : "image/png",
          "url" : "https://tourboogie.com/img/logo.png"
        },
        "businessInfo" : `<p><strong>${businessName}</strong></p><p>123 Fake Street</p><p>Estes Park, CO 11111</p><p><br></p><p>1-800-MY-TOURS</p><p><br></p><p className="text-sm">© 2021</p>`
      },
      "email": email,
      "businessName" : businessName,
      "currency" : "usd",
      "fonts" : {
        "primary" : "elsie",
        "secondary" : "sans"
      },
      "colors" : {
        "primary" : "blue-600"
      },
      "navbar" : {
        "id" : 1,
        "links" : [ {
          "id" : "item-1",
          "text" : "About",
          "url" : "/about"
        }, {
          "id" : "item-2",
          "text" : "Contact",
          "url" : "/contact"
        }, {
          "id" : "item-3",
          "text" : "Tours",
          "url" : "/tours"
        }],
        "logo" : {
          "height" : 48,
          "mime" : "image/png",
          "url" : "https://tourboogie.com/img/logo.png",
          "width" : 48
        }
      }
    },
    "pages" : {
      "about" : {
        "body" : "<p>Tell your story here. Or delete and replace with a different page!</p>",
        "name" : "About",
        "slug" : "about",
        "status" : "published"
      },
      "contact" : {
        "body" : "<p>Put your contact information here.</p>",
        "name" : "Contact us",
        "slug" : "contact",
        "status" : "published"
      },
      "home" : {
        "body" : "body",
        "name" : "Home",
        "image" : "https://firebasestorage.googleapis.com/v0/b/tour-dashboard-2e6fa.appspot.com/o/images%2FLwo3wPu8rCP6lgyMbs4X9rkY46j1%2Famsterdam3.jpg?alt=media&token=0fb86529-dbed-4b98-81b3-c2afc06bff64",
        "slug" : "",
        "status" : "published",
        "text1" : businessName,
        "text2" : "",
        "cta" : "view tours",
        "fontColor" : "white",
        "buttonColor" : "white",
        "modules" : {
          "0" : {
            "component" : 2,
            "draggableId" : "item-0",
            "fields" : {
              "image" : "https://firebasestorage.googleapis.com/v0/b/tour-dashboard-2e6fa.appspot.com/o/PXL_20210105_193618358.jpg?alt=media&token=cbd442a1-9b8c-4465-8367-a91a0392e568",
              "text1" : businessName,
              "text2" : ""
            }
          }
        }
      }
    },
    "tours" : {
      "example_tour" : {
        "description" : "This tour is an example and is automatically added to every new account. This tour is for illustration purposes only.",
        "image" : "https://firebasestorage.googleapis.com/v0/b/tour-dashboard-2e6fa.appspot.com/o/PXL_20200901_214233529.jpg?alt=media&token=de99a18c-3d2f-4c68-a1db-cac7cf30c2f8",
        "images" : [
          "https://firebasestorage.googleapis.com/v0/b/tour-dashboard-2e6fa.appspot.com/o/PXL_20200902_012902342.jpg?alt=media&token=40da7a30-959e-4b71-8819-e939cef64cd4",
          "https://firebasestorage.googleapis.com/v0/b/tour-dashboard-2e6fa.appspot.com/o/IMG_20200806_072948.jpg?alt=media&token=dd11a2b5-663b-41ad-9d48-7977a647f413"
        ],
        "name" : "Example tour",
        "icons" : {
            "groupSize" : "Small",
            "activityLevel" : "Easy",
            "address" : "Breckenridge, CO"
        },
        "covid" : "Covid safety protocol",
        "video" : "https://youtu.be/Kefisg18KHA",
        "tabs" : {
          "0" : {
            "label": "What to bring",
            "content" : "water bottles, etc."
          },
          "1" : {
            "label": "FAQ",
            "content" : "FAQ content"
          },
        },
        "itinerary" : {
          "0" : {
            "label" : "Day 1",
            "content" : "Day 1 activities!"
          }
        },
        "slug" : "example_tour",
        "status" : "published"
      }
    }
  });

  // TODO: add function here to call a serverless stripe thing to make the purchase?

  // add site record to firestore
  /*firestoredb.collection('sites').doc(id).set({
    username,
    email,
    owner: username,
    domain: null
  }, {merge: true });*/

//returns all users from firebase realtime db

export const onceGetUsers = () => db.ref("users").once("value");
export const doGetAnUnser = uid => db.ref(`users/${uid}`).once("value");

// other APIs could come below
