// 1/25/2021: is this deprecated??

/* this component takes in a `user` argument and then queries firebase for the user's stripeId. If there is no stripeId (free account), then object is empty.

12/10/2020:
This component adds the stripeId to the global state. The customer object itself is not available but the ID is so each tab can fetch if they need to.
*/

import firebase from 'firebase/app';

// redux
import store from "../redux/store/index";
import { setConnectedAccountId } from "../redux/actions/index";

function GetConnectedAccount(user) {

    const uid = user

    // query firebase with the uid then return the user's stripeId
    return (
      firebase.database().ref(`/users/${uid}`).once('value')
        .then((snapshot) => {
          var user = (snapshot.val() && snapshot.val()) || null;
            store.dispatch(setConnectedAccountId({
              connectedAccountId: user.connectedAccountId
            }))
        })
    )
}

export default GetConnectedAccount;

