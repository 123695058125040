/* this component takes in a `user` argument and then queries firebase for the user's stripeId. If there is no stripeId (free account), then object is empty.

12/10/2020:
This component adds the stripeId to the global state. The customer object itself is not available but the ID is so each tab can fetch if they need to.
*/

import firebase from 'firebase/app';

// redux
import store from "../redux/store/index";
import { setStripeId } from "../redux/actions/index";

function GetStripeId(user) {
  
    //console.log(user)
    const uid = user

    // query firebase with the uid then return the user's stripeId
    return (
      firebase.database().ref(`/users/${uid}`).once('value')

        .then((snapshot) => {
          var user = (snapshot.val() && snapshot.val()) || null;
            store.dispatch(setStripeId({
              stripeId: user.stripeId
            }))
        })
        /*.then(() => {
          async function setCustomer(stripeId) {
            // const stripeId = cus_IZ8bvYvHqsKWBZ
            const requestUrl = `https://api.tourguidehero.com?query=${stripeId}`;
            const response = await fetch(requestUrl);
            const data = await response.json();
            //return console.log(data.customerObject.subscriptions.data[0].plan.id);
            return store.dispatch(setStripeCustomer({
              stripeCustomer: data.stripeCustomer
            }));
          }
          setCustomer()
        })*/
    )
}

export default GetStripeId;

