import React from "react";
import { firebase } from "../firebase";

import AuthUserContext from "./AuthUserContext"; //using provider's context api

// redux
import { setUser } from "../redux/actions/index";
import store from "../redux/store/index";
import SetSiteMeta from './SetSiteMeta';
import GetStripeId from '../firebase/GetStripeId'
import GetConnectedAccount from './GetConnectedAccount'


const withAuthentication = Component => {

  class WithAuthentication extends React.Component {
    
    state = {
      authUser: null,
      loading: true,
      loggedIn: false
    };

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
            this.setState({
              authUser,
              loggedIn: true,
            });
            store.dispatch(setUser({
              user: authUser,
              loggedIn: true,
              loading: true
            }))
            if (this.state.loggedIn === true) {
              SetSiteMeta(authUser.uid);
              GetStripeId(authUser.uid);
              //SetStripeCust();
              GetConnectedAccount(authUser.uid);
            }
            setTimeout(() => store.dispatch(setUser({
              user: authUser,
              loggedIn: true,
              loading: false
            })), 1000);
                        
          } else {
            this.setState({ authUser: null });
            store.dispatch(setUser({
              loggedIn: false,
              user: null
            }))
          }
      });
    }

    render() {
      const { authUser } = this.state;
      return (
        // passing down the authUser value, so other components can consume it
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;