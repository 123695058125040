import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

// redux
import { connect } from "react-redux";

// all global variables are set inside withAuthentication
import withAuthentication from "./firebase/withAuthentication";

// cache busting
import withClearCache from "./ClearCache";

// analytics
import Analytics from 'react-router-ga';
//import TagManager from 'react-gtm-module'

/*const tagManagerArgs = {
  gtmId: 'AW-875754297'
}*/

// log rocket
import LogRocket from 'logrocket';
LogRocket.init('rlqbo5/tour-boogie');

const Layout = lazy(() => import('./containers/Layout'))
const LoadingScreen = lazy(() => import('./pages/LoadingScreen'))
const Login = lazy(() => import('./pages/Login'))
const Register = lazy(() => import('./pages/Register'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

//TagManager.initialize(tagManagerArgs)

function App() {
  return <ClearCacheComponent />;
}

const mapStateToProps = state => { // https://www.valentinog.com/blog/redux/ https://thoughtbot.com/blog/using-redux-with-react-hooks
  return {
    user: state.user
  };
};

const ConnectedApp = ({ user }) => {

  {/* if coming from the landing site */}
  const action = window.location.href.split('&')[1];

  if (user.user) {
    LogRocket.identify(user.user.uid, {
      email: user.user.email,
      // Add your own custom user variables here, ie:
      subscriptionType: 'N/A'
    });
  }
  
  
  if (user && user.loading === true) {
    return <LoadingScreen />
  } else {
    return (
      <div>
        <Router>
          
          <AccessibleNavigationAnnouncer />
          <Analytics id="G-Y5MSN82XSJ">
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/create-account" component={Register}  />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/" render={() => <Layout user={user.user} />} />
          </Switch>
          
          <Route>
            {user && user.loggedIn === true && user.loading === false  ? 
              <Redirect to="/" />
            : action && action === "register" ?
              <Redirect to="/create-account" />
            :
              <Redirect to="/login" />
            }
            </Route>
          </Analytics>
        </Router>
      </div>
    )
  }
}

const MainApp = withAuthentication(connect(mapStateToProps)(ConnectedApp));

const ClearCacheComponent = withClearCache(MainApp);

export default App