import { SET_USER } from "../constants/action-types";
import { SET_STRIPE_ID } from "../constants/action-types";
import { SET_STRIPE_CUSTOMER } from "../constants/action-types";
import { SET_CONNECTED_ACCOUNT_ID } from "../constants/action-types";
import { SET_REGISTRATION } from "../constants/action-types";
import { SET_PAGEBUILDER } from "../constants/action-types";
import { SET_TOURBUILDER } from "../constants/action-types";
import { SET_NAVBAR } from "../constants/action-types";
import { SET_NAV } from "../constants/action-types";
import { SET_PAGE } from "../constants/action-types";
import { SET_ACCOUNT } from "../constants/action-types";
import { SET_FOOTER } from "../constants/action-types";
import { SET_TOUR } from "../constants/action-types";
import { SET_TOURIMAGEMAIN } from "../constants/action-types";
import { SET_TOURIMAGES } from "../constants/action-types";
import { SET_SITE } from "../constants/action-types";
import { ADD_ARTICLE } from "../constants/action-types";
import { SET_VIEW } from "../constants/action-types";
import { SET_PAGE_SLUG } from "../constants/action-types";

const initialState = {
  user: {
    user: '',
    loading: true
  },
  stripeId: [],
  connecteAccountId: '',
  stripeCustomer: [],
  registration: {
    regStep: 0,
    user: [],
    stripeCustomer: [],
    subdomain: '',
    domain: []
  },
  pageBuilder: {
    pageSaved: undefined,
    showAddNew: false,
    field: '',
    editor : {
      moduleToEdit: ''
    },
    newModule: null
  },
  tourBuilder: {
    field: ''
  },
  navBar: {
    newLinkName: '',
    navSaved: true
  },
  page: {
  },
  account: {
    getStripeAgain: false
  },
  footer: {
    updated: false
  },
  tour: {
    interiorView: 'default',
    slug: null,
    instance: null,
    ticketPrices: [],
  },
  tourImageMain: [],
  tourImages: [],
  site: [],
  articles: [],
  views: [],
  pageSlug: [],
  nav: {
    links: [],
    navChanged: false
  }
};

function rootReducer(state = initialState, action) {
  if (action.type === SET_USER) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  if (action.type === SET_STRIPE_ID) {
    return Object.assign({}, state, {
      stripeId: action.payload
    });
  }
  if (action.type === SET_STRIPE_CUSTOMER) {
    return Object.assign({}, state, {
      stripeCustomer: action.payload
    });
  }
  if (action.type === SET_CONNECTED_ACCOUNT_ID) {
    return Object.assign({}, state, {
      connectedAccountId: action.payload
    });
  }
  if (action.type === SET_REGISTRATION) {
    return {...state, registration: {...state.registration, ...action.payload}
    };
  }
  if (action.type === SET_PAGEBUILDER) {
    return {...state, pageBuilder: {...state.pageBuilder, ...action.payload}
    };
  }
  if (action.type === SET_TOURBUILDER) {
    return {...state, tourBuilder: {...state.tourBuilder, ...action.payload}
    };
  }
  if (action.type === SET_NAVBAR) {
    return {...state, navBar: {...state.navBar, ...action.payload}
    };
  }
  if (action.type === SET_PAGE) {
    return Object.assign({}, state, {
      page: action.payload
    });
  }
  if (action.type === SET_ACCOUNT) {
    return Object.assign({}, state, {
      account: action.payload
    });
  }
  if (action.type === SET_FOOTER) {
    return Object.assign({}, state, {
      footer: action.payload
    });
  }
  if (action.type === SET_NAV) {
    return Object.assign({}, state, {
      nav: action.payload
    });
  }
  if (action.type === SET_TOUR) {
    return {...state, tour: {...state.tour, ...action.payload}
    };
  }
  if (action.type === SET_TOURIMAGEMAIN) {
    return {...state, tourImageMain: {...state.tourImageMain, ...action.payload}
    };
  }
  if (action.type === SET_TOURIMAGES) {
    return Object.assign({}, state, {
      tourImages: action.payload
    });
  }
  if (action.type === SET_SITE) {
    return Object.assign({}, state, {
      site: action.payload
    });
  }
  if (action.type === ADD_ARTICLE) {
    return Object.assign({}, state, {
      articles: state.articles.concat(action.payload)
    });
  }
  if (action.type === SET_VIEW) {
    return Object.assign({}, state, {
      views: action.payload
    });
  }
  if (action.type === SET_PAGE_SLUG) {
    return Object.assign({}, state, {
      pageSlug: action.payload
    });
  }
  return state;
}

export default rootReducer;