export default {
    button: {
        primary: {
          base: 'text-white bg-blue-600 border border-transparent',
          active: 'active:shadow-innerLg active:text-shadow-md',
        },
        outline: {
          base: 'text-gray-600 border-gray-300 border focus:outline-none',
          active: 'active:shadow-innerMd active:text-shadow-md',
          disabled: 'opacity-50 cursor-not-allowed bg-gray-300'
        },
        link: {
          base: 'text-gray-600 focus:outline-none border border-transparent',
          active: 'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray',
          disabled: 'opacity-50 cursor-not-allowed'
        },
        size: {
          larger: 'px-10 py-4 rounded-lg',
          large: 'px-5 py-3 rounded-lg',
          regular: 'px-4 py-2 rounded-lg text-sm',
          small: 'px-3 py-1 rounded-md text-sm',
          smaller: 'px-2 py-1 rounded-md text-xs',
          icon: {
            larger: 'p-4 rounded-lg',
            large: 'p-3 rounded-lg',
            regular: 'p-2 rounded-lg',
            small: 'p-2 rounded-md'
          },
          pagination: 'px-3 py-1 rounded-md text-xs'
        },
    },
    // Label
    label: {
      base: 'block text-sm text-gray-800',
      // check and radio get this same style
      check: 'inline-flex items-center',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    input: {
      base: 'block w-full text-sm focus:outline-none form-input leading-5',
      active: 'focus:border-blue-400 focus:shadow-outline-blue',
      disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
      valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green ',
      invalid: 'border-red-600 focus:border-red-400 focus:shadow-outline-red',
      radio: 'text-blue-600 form-radio focus:border-blue-400 focus:outline-none focus:shadow-outline-blue',
      checkbox: 'text-blue-600 form-checkbox focus:border-blue-400 focus:outline-none focus:shadow-outline-blue'
    },
    // Textarea
    textarea: {
      base: 'block w-full text-sm form-textarea focus:outline-none',
      active: 'focus:border-blue-400 focus:shadow-outline-blue',
      disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
      valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green',
      invalid: 'border-red-600 focus:border-red-400 focus:shadow-outline-red'
    },
    select: {
      base: 'block w-full text-sm focus:outline-none',
      active: 'focus:border-blue-400 focus:shadow-outline-blue',
      select: 'form-select leading-5',
      multiple: 'form-multiselect',
      disabled: 'cursor-not-allowed opacity-50 bg-gray-300',
      valid: 'border-green-600 focus:border-green-400 focus:shadow-outline-green',
      invalid: 'border-red-600 focus:border-red-400 focus:shadow-outline-red'
    },
    modalHeader: {
      base: 'mt-2 mb-2 text-lg font-semibold text-gray-700'
    },
    modalFooter: {
      base: 'flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:flex-row bg-gray-50'
    },
    // Card
    // overflow-hidden
    card: {
      base: 'min-w-0 rounded-lg',
      "default": 'bg-white'
    },
    cardBody: {
      base: 'p-4'
    },
     // Badge
    badge: {
      base: 'inline-flex px-2 text-xs font-medium leading-5 rounded-md',
      success: 'text-green-700 bg-green-100',
      danger: 'text-red-700 bg-red-100',
      warning: 'text-orange-700 bg-orange-100',
      neutral: 'text-gray-700 bg-gray-100',
      primary: 'text-purple-700 bg-purple-100'
    },
    // TableHeader
    tableHeader: {
      base: 'text-xs font-semibold tracking-wide text-left text-gray-700 uppercase border-b bg-gray-50'
    },
    // TableContainer
    tableContainer: {
      base: 'w-full overflow-hidden rounded-md border border-gray-200'
    },
    // TableCell
    tableCell: {
      base: 'px-4 py-3'
    },
    // TableBody
    tableBody: {
      base: 'bg-white divide-y text-gray-900'
    },
    // TableFooter
    tableFooter: {
      base: 'px-4 py-3 border-t bg-gray-50 text-gray-500'
    },
    // HelperText
    helperText: {
      base: 'text-xs',
      valid: 'text-green-600',
      invalid: 'text-red-600'
    },
  }