// src/js/constants/action-types.js

export const SET_USER = "SET_USER";
export const SET_STRIPE_ID = "SET_STRIPE_ID";
export const SET_STRIPE_CUSTOMER = "SET_STRIPE_CUSTOMER";
export const SET_CONNECTED_ACCOUNT_ID = "SET_CONNECTED_ACCOUNT_ID";
export const SET_REGISTRATION = "SET_REGISTRATION";
export const SET_PAGEBUILDER = "SET_PAGEBUILDER";
export const SET_TOURBUILDER = "SET_TOURBUILDER";
export const SET_PAGE = "SET_PAGE";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_FOOTER = "SET_FOOTER";
export const SET_NAVBAR = "SET_NAVBAR";
export const SET_NAV = "SET_NAV";
export const SET_TOUR = "SET_TOUR";
export const SET_TOURIMAGEMAIN = "SET_TOURIMAGEMAIN";
export const SET_TOURIMAGES = "SET_TOURIMAGES";
export const SET_SITE = "SET_SITE";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const SET_VIEW = "SET_VIEW";
export const SET_PAGE_SLUG = "SET_PAGE_SLUG";