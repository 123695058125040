import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/app'
import "firebase/database";
import "firebase/storage";

// analytics
import "firebase/performance";

var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_BASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
  };

  
// Initialize Firebase
if (!firebase.apps.length) {
    //initializing with the config object
    firebase.initializeApp(firebaseConfig);
}
  
  //separting database API and authentication
  const db = firebase.database();
  const firestoredb = firebase.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const perf = firebase.performance();

  export { db, auth, firestoredb, storage, perf };  