// src/js/actions/index.js

import { SET_USER } from "../constants/action-types";
import { SET_STRIPE_ID } from "../constants/action-types";
import { SET_STRIPE_CUSTOMER } from "../constants/action-types";
import { SET_CONNECTED_ACCOUNT_ID } from "../constants/action-types";
import { SET_REGISTRATION } from "../constants/action-types";
import { SET_PAGEBUILDER } from "../constants/action-types";
import { SET_TOURBUILDER } from "../constants/action-types";
import { SET_TOURIMAGES } from "../constants/action-types";
import { SET_TOURIMAGEMAIN } from "../constants/action-types";
import { SET_PAGE } from "../constants/action-types";
import { SET_ACCOUNT } from "../constants/action-types";
import { SET_FOOTER } from "../constants/action-types";
import { SET_NAVBAR } from "../constants/action-types";
import { SET_NAV } from "../constants/action-types";
import { SET_TOUR } from "../constants/action-types";
import { SET_SITE } from "../constants/action-types";
import { ADD_ARTICLE } from "../constants/action-types";
import { SET_VIEW } from "../constants/action-types";
import { SET_PAGE_SLUG } from "../constants/action-types";

export function setUser(payload) {
  return { type: SET_USER, payload };
}
export function setStripeId(payload) {
  return { type: SET_STRIPE_ID, payload };
}
export function setStripeCustomer(payload) {
  return { type: SET_STRIPE_CUSTOMER, payload };
}
export function setConnectedAccountId(payload) {
  return { type: SET_CONNECTED_ACCOUNT_ID, payload };
}
export function setRegistration(payload) {
  return { type: SET_REGISTRATION, payload };
}
export function setPageBuilder(payload) {
  return { type: SET_PAGEBUILDER, payload };
}
export function setTourBuilder(payload) {
  return { type: SET_TOURBUILDER, payload };
}
export function setTourImageMain(payload) {
  return { type: SET_TOURIMAGEMAIN, payload };
}
export function setTourImages(payload) {
  return { type: SET_TOURIMAGES, payload };
}
export function setNavBar(payload) {
  return { type: SET_NAVBAR, payload };
}
export function setNav(payload) {
  return { type: SET_NAV, payload };
}
export function setPage(payload) {
  return { type: SET_PAGE, payload };
}
export function setAccount(payload) {
  return { type: SET_ACCOUNT, payload };
}
export function setFooter(payload) {
  return { type: SET_FOOTER, payload };
}
export function setTour(payload) {
  return { type: SET_TOUR, payload };
}
export function setSite(payload) {
  return { type: SET_SITE, payload };
}
export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}
export function setView(payload) {
  return { type: SET_VIEW, payload };
}
export function setPageSlug(payload) {
  return { type: SET_PAGE_SLUG, payload };
}