import React from 'react'
import firebase from 'firebase/app';

// redux
import store from "../redux/store/index";
import { setSite } from "../redux/actions/index";

const SetSiteMeta = (user) => {

    const uid = user

    return (
      firebase.database().ref(`/sites/${uid}`).once('value').then((snapshot) => {
        var site = (snapshot.val() && snapshot.val()) || null;
          store.dispatch(setSite({
            siteGlobal: site.global,
            domain: site.domain,
            subdomain: site.subdomain
          }))
      })
    )
}

export default SetSiteMeta;